@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
}

html,
body {
    scroll-padding-top: 100px;
    overflow: hidden;
    background: black;
    font-family: "Montserrat", sans-serif;
    color: var(--text-primary-color);
    font-size: 20px;
    overflow-x: hidden;
}

/* This is a variable */
:root {
    --background-black: hsl(0 0% 0%/0.5);
    --text-primary-color: aliceblue;

    /*Color pallete: https://coolors.co/331832-d81e5b-f0544f-c6d8d3-fdf0d5*/
    --custom-yellow: #ffe0a3;
    --custom-orange: #F0544F;
    --custom-red: #D81E5B;
    --custom-purple: #331832;

    /* Contact form colors */
    --bodyColor: #292a2b;
    --borderFormEls: hsl(0, 0%, 10%);
    --bgFormEls: hsl(0, 0%, 14%);
    --bgFormElsFocus: hsl(0, 7%, 20%);
}


/* Experience ------------------------------------------ */
/* Target the canvas */
.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


/* Ensure that the canvas stays in place when scrolling */
.experience {
    position: fixed;
    width: 100vw;
    height: 100vh;
}

/* OVERLAY FOR PRELOADER TRANSITIONS ------------------------- */
.overlay-first {
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    z-index: 99;
    background-color: var(--custom-orange);
}

.overlay-second {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    z-index: 98;
    background-color: var(--custom-red);
}

.paragraph-link {
    color: white;
    text-decoration: underline;
    font-weight: 500;
}

/* PRELOADER -------------------------------------------- */
.hidden-preloader {
    display: none;
}

.preloader {
    background-color: black;
    width: 100%;
    height: 100vh;
    position: fixed;
    opacity: 1;
    z-index: 99999999999999999;
}

.preloader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-text {
    color: var(--custom-yellow);
    padding-right: 10px;
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 6px;
    background-color: var(--custom-yellow);
    animation: load 1s ease-in-out infinite;
}

.circle:nth-child(2) {
    animation-delay: 0.1s;
}

.circle:nth-child(3) {
    animation-delay: 0.2s;
}

@keyframes load {
    0% {
        transform: translateY(0)
    }

    20% {
        transform: translateY(-12px)
    }

    40% {
        transform: translateY(0)
    }

}

/* PRELOADER TEXT -------------------------------------------- */
.preloader-div {
    margin-top: 50vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    text-transform: none;
}

.preloader-text {
    color: var(--custom-yellow);
    text-align-last: center;
    position: fixed;
    opacity: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

/* MOUSE ANIMATION -------------------------------------- */
.mouse-wrapper {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mouse {
    width: 25px;
    height: 45px;
    border: 3px solid var(--custom-yellow);
    border-radius: 60px;
    text-align: center;
}

.mouse::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 10px;
    background-color: var(--custom-yellow);
    transform: translateX(-50%);
    border-radius: 50%;
    opacity: 1;
    animation: mouse 2s infinite;
}

@keyframes mouse {
    from {
        opacity: 1;
        top: 10px;
    }

    to {
        opacity: 0;
        top: 30px
    }
}

.mouse-text {
    left: 45%;
    margin-top: 8%;
    margin-left: -40px;
    color: var(--custom-yellow);
}


/* HEADER ----------------------------------------------- */
.header {
    top: 0;
    left: 0;
    right: 0;
    /* padding: 40px 35px 0; */
    margin-top: 5vh;
    margin-left: 2vw;
}

.header-title {
    text-transform: uppercase;
    opacity: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.header-description {
    font-style: italic;
    opacity: 0;
    font-size: medium;
}

/* MENU --------------------------------------------------- */
.flex {
    display: flex;
    gap: var(--gap, 1rem);
}

ul {
    margin-top: 5%;
}

ul a {
    color: white;
    text-decoration: none;
}

ul a:hover {
    color: white;
    transition: color 400ms ease-out;
}

ul a:not(:hover) {
    color: white;
    transition: color 200ms ease-out;
}

a::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 3px;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #000;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: color 0.1s, transform 0.2s ease-out;
}

.active {
    opacity: 0;
    font-weight: 400;
}

.primary-header {
    width: 100%;
    height: 15vh;
    align-items: center;
    display: flex;
    justify-content: space-between;
    z-index: 100;
    position: fixed;
}

.primary-navigation {
    opacity: 0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    text-transform: uppercase;
    list-style: none;
    margin: 0;
    background: hsl(0 0% 0%/0.55);
}

.mobile-nav-toggle {
    display: none;
}

@supports (backdrop-filter: blur(1rem)) {
    .primary-navigation {
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(1rem);
    }
}

.primary-navigation a {
    text-decoration: none;
    font-weight: 600;
}

.current-section {
    background-color: var(--custom-orange);
    transition: 0.05s ease background-color;
}

/* Since we are using SplitType, the letters will be converted to "char" elements
that we want to hide to then perform an animation
*/
.char {
    /* Move the letters down */
    opacity: 0;
    transform: translateY(-115px);
    transition: transform .5s;
}

/* Page itself ------------------------------------------ */
.page {
    /* z-index: 99999; */
    width: 100%;
    height: 85vh;
    overflow: hidden;
}

.details {
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    z-index: 1000;
    background-color: var(--custom-purple);
    color: whitesmoke;
    margin-top: 20%;
}


/*! This is what disables OrbitControls to work */
.page-wrapper {
    position: relative;
}

/* Section formatting ------------------------------------------ */
.section-margin {
    height: 100vh;
    width: 100%;
}

.section {
    /* height: 150vh; */
    width: 45%;
    padding: 17vh 4%;
    margin: 0;
    background-color: hsl(0 0% 0%/0.75);
}

@supports (backdrop-filter: blur(1rem)) {
    .section {
        background: rgba(255, 224, 163, 0.75);
        backdrop-filter: blur(1rem);
    }
}

.left {
    margin-right: auto;
    margin-left: 2vw;
    border-radius: 5vw;

}

.right {
    margin-left: auto;
    margin-right: 2vw;
    border-radius: 5vw;
}

.section-detail-wrapper {
    position: relative;
    padding: 5% 0 0 0;
}

.section-heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.8;
    margin-top: 64px;
}

.section-text {
    line-height: 1.6;
    /* margin-top: 18px; */
    font-size: 20px;
}

/* Fancy Decoration Part of Section */
.section-title {
    position: relative;
    color: var(--text-primary-color);
    font-size: 48px;
    text-transform: uppercase;
}

.details-wrapper {
    min-height: 75vh;
}

.details-title {
    padding-top: 30vh;
    margin: auto;
    text-align: center;
    width: 75vw;
    justify-content: center;
    align-items: center;
    color: var(--text-primary-color);
    font-size: 48px;
    text-transform: uppercase;
}

.details-subtitle {
    padding-top: 5vh;
    margin: auto;
    text-align: center;
    width: 75vw;
    justify-content: center;
    align-items: center;
    color: var(--text-primary-color);
    font-size: 28px;
    text-transform: uppercase;
}

.details-text {
    padding-top: 5vh;
    width: 75vw;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 1.6;
    /* margin-top: 18px; */
    font-size: 20px;
}

/* Hero section ----------------------------------------- */
.hero {
    width: 100vw;
    height: 100vh;
}

.hero-wrapper {
    position: relative;
    height: 100%;
    width: 95%;
    margin: 0;
    /* max-width: 1100px; */
}

.hero-main {
    position: absolute;
    bottom: 250px;
    left: 10%;
    color: var(--text-primary-color);
}

.hero-main-title {
    font-size: 28px;
}

.hero-main-description {
    font-size: 18px;
}

.hero-first {
    opacity: 0;
    position: absolute;
    top: 5%;
    text-align: right;
    right: 0%;
    color: var(--text-primary-color);

}

/* CLASSES TO SHOW THE TEXT ANIMATIONS */
.hidden {
    opacity: 0;
    transition: all 0.25s;
}

.show {
    opacity: 1;
    transition: all 0.25s;
}

/* Media queries */
@media (max-width: 1072px) {

    /* --- MOBILE --- */
    .section {
        width: 75%;
    }

    .preloader-text {
        font-size: larger;
    }

    .mouse-wrapper {
        margin-top: 50%;
        margin-left: 7.5%;
    }

    .primary-navigation {
        --gap: 2em;
        z-index: 1000;
        position: fixed;
        inset: 0 0 0 30%;
        flex-direction: column;
        padding: min(30vh, 10rem) 2em;
        transform: translateX(100%);
        transition: transform 350ms ease-out;
    }

    .primary-navigation[data-visible="true"] {
        transform: translateX(0);
    }

    .mobile-nav-toggle {
        display: none;
        position: absolute;
        z-index: 9999;
        background-color: transparent;
        background-image: url(../static/menu/icon-hamburger.svg);
        background-repeat: no-repeat;
        width: 1rem;
        border: 0;
        aspect-ratio: 1;
        top: 2rem;
        right: 2rem;
    }

    .mobile-nav-toggle[aria-expanded="true"] {
        background-image: url(../static/menu/icon-close.svg);
    }

}


@media (min-width: 1072px) {

    .mouse-wrapper {
        margin-top: 10%;
    }

    .primary-navigation {
        padding-block: 1rem;
        /* Top and bottom padding*/
        padding-inline: clamp(3rem, 5vw, 10rem);
    }
}

/****************/
/* CONTACT FORM */
/****************/
.my-form {
    margin: 5%;
    height: 80vh;
}

.my-form a {
    color: inherit;
}

.my-form input,
.my-form select,
.my-form textarea,
.my-form button {
    font-family: inherit;
}

.my-form button,
.my-form label {
    cursor: pointer;
}

.my-form select {
    appearance: none;
}

/* Remove native arrow on IE */
.my-form select::-ms-expand {
    display: none;
}

/*Remove dotted outline from selected option on Firefox*/
/*https://stackoverflow.com/questions/3773430/remove-outline-from-select-box-in-ff/18853002#18853002*/
/*We use !important to override the color set for the select on line 99*/
select:-moz-focusring {
    color: transparent !important;
    text-shadow: 0 0 0 var(--white);
}

textarea {
    resize: none;
}

.my-form ul {
    list-style: none;
}


.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1.5rem;
}


/* FORM ELEMENTS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form li,
.my-form .grid>*:not(:last-child) {
    margin-bottom: 1.5rem;
}

.my-form select,
.my-form input,
.my-form textarea,
.my-form button {
    font-family: "JetBrains Mono", monospace;
    width: 100%;
    padding: 15px 10px;
    border: 1px solid var(--borderFormEls);
    color: #36395A;
    background: #FCFCFD;
    transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
        transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.my-form textarea {
    height: 170px;
}

.my-form ::placeholder {
    color: inherit;
    /*Fix opacity issue on Firefox*/
    opacity: 1;
}

.my-form select:focus,
.my-form input:focus,
.my-form textarea:focus {
    transform: scale(1.02);
}

.my-form *:required,
.my-form select {
    background-repeat: no-repeat;
    background-position: center right 12px;
    background-size: 15px 15px;
}

.my-form *:required {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/asterisk.svg);
}

.my-form select {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/down.svg);
}

.my-form *:disabled {
    cursor: default;
    filter: blur(2px);
}


/* FORM BTNS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form .required-msg {
    display: none;
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/asterisk.svg) no-repeat center left / 15px 15px;
    padding-left: 20px;
    font-size: 14px;
}

.my-form .btn-grid {
    position: relative;
    overflow: hidden;
    transition: filter 0.2s;
}

.my-form button {
    font-weight: bold;
}

.my-form button>* {
    display: inline-block;
    width: 100%;
    transition: transform 0.4s ease-in-out;
}

.my-form button .back {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-110%, -50%);
}

.my-form button:enabled:hover .back,
.my-form button:focus .back {
    transform: translate(-50%, -50%);
}

.my-form button:enabled:hover .front,
.my-form button:focus .front {
    transform: translateX(110%);
}


/* CUSTOM CHECKBOX
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form input[type="checkbox"] {
    position: absolute;
    left: -9999px;
}

.my-form input[type="checkbox"]+label {
    position: relative;
    display: inline-block;
    padding-left: 2rem;
    transition: background 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.my-form input[type="checkbox"]+label::before,
.my-form input[type="checkbox"]+label::after {
    content: '';
    position: absolute;
}

.my-form input[type="checkbox"]+label::before {
    left: 0;
    top: 6px;
    width: 18px;
    height: 18px;
    border: 2px solid var(--white);
}

.my-form input[type="checkbox"]:checked+label::before {
    background: var(--red);
}

.my-form input[type="checkbox"]:checked+label::after {
    left: 7px;
    top: 7px;
    width: 6px;
    height: 14px;
    border-bottom: 2px solid var(--white);
    border-right: 2px solid var(--white);
    transform: rotate(45deg);
}


/* MQ
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (min-width: 600px) {
    .my-form .grid {
        display: grid;
        grid-gap: 1.5rem;
    }

    .my-form .grid-2 {
        grid-template-columns: 1fr 1fr;
    }

    .my-form .grid-3 {
        grid-template-columns: auto auto auto;
        align-items: center;
    }

    .my-form .grid>*:not(:last-child) {
        margin-bottom: 0;
    }

    .my-form .required-msg {
        display: block;
    }
}

@media screen and (min-width: 541px) {
    .my-form input[type="checkbox"]+label::before {
        top: 50%;
        transform: translateY(-50%);
    }

    .my-form input[type="checkbox"]:checked+label::after {
        top: 3px;
    }
}

/* BUTTONS */
.button-link {
    display: block;
    margin: 5% auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    appearance: none;
    background-color: #FCFCFD;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-decoration: none;
    transition: box-shadow 0.25s, transform 0.25s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
}

.button-link:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-link:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
}

.button-link:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

.image-container {
    text-align: center;
    display: block;
    margin: 1% auto;
}

.image-container .notion-img {
    width: 75%;
    height: 75%;
}